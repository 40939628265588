import React from 'react';
import logo from './logo.png';  // 确保logo.png位于src文件夹中
import './App.css';

function App() {
  return (
    <div className="container">
      <div className="content">
        <img src={logo} alt="Check 3 Logo" className="logo" />
        <h1 className="title">Check 3</h1>
        <p className="tagline">The ultimate AI-powered to-do list and project management tool.</p>
      </div>
    </div>
  );
}

export default App;
